<template>
  <div>
    <ol class="breadcrumb float-xl-right">
      <li class="breadcrumb-item">
        <router-link :to="{name:'dashboard'}">Dashboard</router-link>
      </li>
      <li class="breadcrumb-item">
        <router-link :to="{name:'users'}">Users</router-link>
      </li>
      <li class="breadcrumb-item"><a href="#">Link Phone</a></li>
    </ol>
    <h1 class="page-header">Link Phone</h1>
    <panel noButton="true" title="Activate User Phone Number" variant="panel-default">
      <div v-html="$error.handle(error)" />
      <form @submit.prevent="submit">
        <div class="row">
          <div
              :class="{ 'has-feedback has-error': errors.has('first_name')}"
              class="col-md-4 form-group"
          >
            <label>Email</label>
            <input
                v-model="user.email"
                v-validate="'required'"
                class="form-control"
                name="first_name"
                type="text"
            >
            <small
                v-if="errors.has('email')"
                class="help-block"
            >
              {{ errors.first('email') }}
            </small>
          </div>
          <div
              :class="{ 'has-feedback has-error': errors.has('id_number')}"
              class="col-md-4 form-group"
          >
            <label>ID Number </label>
            <input
                v-model="user.id_number"
                v-validate="'required'"
                class="form-control"
                name="id_number"
                type="text"
            >
            <small
                v-if="errors.has('id_number')"
                class="help-block"
            >
              {{ errors.first('id_number') }}
            </small>
          </div>
          <div
              :class="{ 'has-feedback has-error': errors.has('phone_number')}"
              class="col-md-6 form-group"
          >
            <label>Phone </label>
            <input
                v-model="user.phone_number"
                v-validate="'required|max:12'"
                class="form-control"
                placeholder="254..."
                name="phone_number"
                type="text"
            >
            <small
                v-if="errors.has('phone_number')"
                class="help-block"
            >
              {{ errors.first('phone_number') }}
            </small>
          </div>
          <div class="col-md-12">
            <button
                v-if="loading"
                type="button"
                class="btn btn-warning"
            >
              Loading <i class="fa fa-spinner spin" />
            </button>
            <button
                v-else
                type="submit"
                class="btn btn-primary"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </panel>
  </div>
</template>

<script>
import helper from "@/mixins/helper";

export default {
  mixins: [helper],
  data() {
    return {
      user: {},
      loading: false,
      error: '',
    }
  },
  methods: {
    submit() {
      return this.$validator.validateAll().then(result => {
        if (result) {
          this.loading = true;
          this.$axios.post('/api/v1/phone-verification', {
            'email': this.user.email,
            'id_number': this.user.id_number,
            'phone_number': this.user.phone_number
          }).then((resp) => {
            this.$toastr.s('The user has been updated.', 'Saved');
            this.$router.push({name: 'users.show', params: {id: resp.data.user_unique_id}});
          }).catch(error => {
            this.error = error.response;
          }).finally(() => {
            this.loading = false;
          });
        }
      });

    }
  }
}
</script>